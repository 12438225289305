const kebabCase = require("lodash.kebabcase")

exports.convertNameToSlug = name => {
  return kebabCase(name)
}

exports.convertAllPlacesToArray = placesJson => {
  const allPlacesArray = []
  placesJson.forEach(country => {
    allPlacesArray.push(...country.cities)
    if (country.cities.length > 1) {
      allPlacesArray.push(country.name)
    }
  })
  return allPlacesArray
}

exports.getPostsByPlace = (allPlacesArray, posts) => {
  const postsByPlace = new Map()
  allPlacesArray.forEach(place => {
    let numPostsForPlace = posts.filter(post =>
      post.node.frontmatter.tags.includes(place)
    ).length
    postsByPlace.set(place, numPostsForPlace)
  })
  return postsByPlace
}
