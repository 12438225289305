import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Tag from "../components/tag"

const Tags = ({ data, location }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData
  const tags = data.allMdx.group
  const pageTitle = "Todas as tiquetas (tags)"
  const byQuantityDesc = (a, b) =>
    a.totalCount > b.totalCount ? -1 : b.totalCount > a.totalCount ? 1 : 0

  return (
    <Layout description={description} logo={logo}>
      <Seo
        title={pageTitle}
        description="Lista com todas a todas as etiquetas (ou tags, em inglês) aplicadas aos textos do blog."
      />
      <div className="post-wrapper">
        <h1 itemProp="headline">{pageTitle}</h1>
        {tags
          .sort(byQuantityDesc)
          .map(t => (
            <Tag
              key={t.fieldValue}
              tagName={t.fieldValue}
              count={t.totalCount}
            />
          ))
          .reduce((prev, curr) => [prev, ", ", curr])}
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
