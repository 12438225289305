import { Link } from "gatsby"
import React from "react"

import { convertNameToSlug } from "../utils/utils"

const Tag = ({ tagName, count }) => {
  const tagSlug = convertNameToSlug(tagName)
  return (
    <span className="tag-in-line">
      <Link to={"/tag/" + tagSlug}>
        {tagName + (count ? ` (${count})` : "")}
      </Link>
    </span>
  )
}

export default Tag
